import { useEffect, useState } from 'react';
import { createAuthLink, AuthOptions } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink, NormalizedCacheObject } from '@apollo/client';
import { TOKEN } from '../global/consts';

interface DataResponse {
  realtimeDomain: string;
  domain: string;
  region: string;
  path: string;
}

export const useSubscriptionClient = () => {
  const [subscriptionClient, setSubscriptionClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const [connectionInit, setConnectionInit] = useState<boolean>(true);
  const getToken = (token: string) => `${token}.gamer-view`;

  useEffect(() => {
    if (connectionInit) {
      const url = import.meta.env.VITE_SUBSCRIPTIONS_URI;
      const token = localStorage.getItem(TOKEN);
      const auth: AuthOptions = {
        type: 'AWS_LAMBDA',
        token: async () => getToken(token as string),
      };
      if (url) {
        fetch(url)
          .then((response) => response.json())
          .then((data: DataResponse) => {
            const httpLink = createHttpLink({ uri: `https://${data.domain}${data.path}` });
            const link = ApolloLink.from([
              createAuthLink({ url: `https://${data.domain}${data.path}`, region: data.region, auth }),
              createSubscriptionHandshakeLink(
                { url: `https://${data.domain}${data.path}`, region: data.region, auth },
                httpLink,
              ),
            ]);
            const subsClient = new ApolloClient({
              link,
              cache: new InMemoryCache(),
            });

            setSubscriptionClient(subsClient);
            setConnectionInit(false);
          });
      }
    }
  }, [connectionInit]);

  return { subscriptionClient, setConnectionInit };
};
