import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserRole } from '../../global/consts';
import { GET_USER_QUERY } from '../../global/gql/queries';
import avatar from '../../global/images/avatar.webp';
import reneLogoBig from '../../global/images/rene-logo-new-big.png';
import reneLogo from '../../global/images/rene-logo-new-small.png';
import { UserData } from '../../global/interfaces';
import { BRANDS, CAMPAIGNS, DOCS_LINK, GAMES, LOGIN, ORG, ORG_MANAGEMENT, SETTINGS } from '../../global/routes';
import { useReneQuery } from '../../hooks/useReneQuery';
import { formatNumber, isRoleAllowed, unsubscribeAllSubscriptions } from '../../utils';
import AsidePopupMenu from '../aside-popup-menu/aside-popup-menu';
import Icon from '../Icon/Icon';
import Notifications from '../notifications/notifications';
import OrganizationMenu from './organization-menu/organization-menu';

import './header.scss';

const DeveloperMenu = ({ user }: { user: UserData }) => {
  const { pathname } = useLocation();
  return (
    <>
      {user && (
        <Link to={`/${ORG}`} className={pathname === `/${ORG}` ? 'page-selected' : ''}>
          Home
        </Link>
      )}
      {user && isRoleAllowed(user?.role, [UserRole.DEVELOPER]) && (
        <Link to={`/${ORG}/${GAMES}`} className={pathname.includes(`/${ORG}/${GAMES}`) ? 'page-selected' : ''}>
          Games
        </Link>
      )}
      {user && isRoleAllowed(user?.role, [UserRole.CREATOR]) && (
        <Link to={`/${ORG}/${CAMPAIGNS}`} className={pathname.includes(`/${ORG}/${CAMPAIGNS}`) ? 'page-selected' : ''}>
          Campaigns
        </Link>
      )}
      {user && isRoleAllowed(user?.role, [UserRole.ADVERTISER]) && (
        <Link to={`/${ORG}/${BRANDS}`} className={pathname.includes(`/${ORG}/${BRANDS}`) ? 'page-selected' : ''}>
          Brands
        </Link>
      )}
    </>
  );
};

const GamerMenu = () => {
  const { pathname } = useLocation();
  return (
    <>
      <Link to={`/${GAMES}`} className={pathname === `/${GAMES}` ? 'page-selected' : ''}>
        Games
      </Link>
      <Link to={`/${GAMES}/offers`} className={pathname.includes(`/${GAMES}/offers`) ? 'page-selected' : ''}>
        Inbox
      </Link>
    </>
  );
};

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { data, client, loading } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);
  const isOrgView = !!location.pathname.match(`/${ORG}`);
  const isNotGamer = data && data.User.role !== UserRole.GAMER;

  const logout = () => {
    localStorage.clear();
    client.clearStore();
    unsubscribeAllSubscriptions();
    navigate(`/${LOGIN}`);
  };

  if (data && !data?.User.verified.isEmail) {
    logout();
  }

  return (
    <div className="header-container">
      <header className="header">
        <div className="header__hamburger_menu">
          <button type="button" onClick={() => setIsMenuOpen(true)}>
            <Icon name="hamburger" />
          </button>
          <AsidePopupMenu isMenuOpen={isMenuOpen} setIsOpen={setIsMenuOpen}>
            <div>{isOrgView ? <DeveloperMenu user={data?.User as UserData} /> : <GamerMenu />}</div>
          </AsidePopupMenu>
        </div>
        <div className="header__rene_logo" onClick={() => navigate(isOrgView ? `/${ORG}` : `/${GAMES}`)}>
          <picture>
            <source srcSet={reneLogoBig} media="(min-width: 780px)" />
            <img src={reneLogo} alt="reneverse" />
          </picture>
        </div>
        <div className="header__gamer-earnings">
          <div>
            <p>${formatNumber(parseInt((data?.User?.earnings as string) || '0'), 2)}</p>
          </div>
        </div>
        <div className="header__navigation">
          {isOrgView ? <DeveloperMenu user={data?.User as UserData} /> : <GamerMenu />}
        </div>
        <div className="header__user_actions">
          <Notifications user={data?.User} />
          <div className="header__user_actions_info">
            <div className="header__user_actions_info_image">
              <img src={data?.User?.image?.url || avatar} alt="profile" />
            </div>
            <div className="header__user_actions_info_profile_dropdown">
              {!loading && (
                <div className="header__user_actions_info_profile_dropdown_user">
                  <p>{`${data?.User?.data.firstName} ${data?.User?.data.lastName}`}</p>
                  <p>{data?.User.email}</p>
                </div>
              )}
              {isNotGamer ? (
                <Link
                  className="header__user_actions_info_profile_dropdown_view"
                  to={isOrgView ? `${GAMES}` : `${ORG}`}
                >
                  <Icon name={isOrgView ? 'gamepad' : 'code'} />
                  <p>Switch to {isOrgView ? 'player view' : 'developer view'}</p>
                </Link>
              ) : null}
              {isNotGamer ? <OrganizationMenu /> : null}
              <Link to={`/${SETTINGS}/profile`} className="header__user_actions_info_profile_dropdown_account">
                Manage account
              </Link>
              <Link to={`/${SETTINGS}/credit`}>Credits</Link>

              <Link to={`/${ORG_MANAGEMENT}`}>Organizations</Link>
              <a href={DOCS_LINK} target="_blank" rel="noreferrer">
                Help
              </a>
              <button className="header__user_actions_info_profile_dropdown_logout" type="button" onClick={logout}>
                Logout
              </button>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
