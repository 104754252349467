import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, ApolloProvider, createHttpLink, ServerError, from, InMemoryCache } from '@apollo/client';
import { ErrorLink, onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { LOGIN } from './global/routes';
import { TOKEN } from './global/consts';
import { checkTokenExpiration, refreshToken } from './utils';
import { NotificationProvider } from './context/notification-context/notification-provider';
import { UpdatePopUpProvider } from './context/update-message-context/update-message-provider';
import { ErrorProvider } from './context/error-context/error-provider';
import App from './App';

const reneApiUri = import.meta.env.VITE_RENE_API_URI;
const reneApiKey = import.meta.env.VITE_RENE_API_KEY as string;

const httpLink = createHttpLink({
  uri: reneApiUri,
});

const authLink = setContext(async (_, { headers }: { headers?: { authorization: string } }) => {
  // get the authentication token from local storage if it exists
  let token = localStorage.getItem(TOKEN);

  if (token && checkTokenExpiration(token)) {
    const newToken = await refreshToken(token);
    if (newToken) {
      token = newToken;
    }
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      'x-api-key': token ? '' : reneApiKey,
      authorization: token ? `Bearer ${token}.${headers?.authorization}` : '',
    },
  };
});

const errorLink = onError((({ networkError, operation }) => {
  if (networkError && (networkError as ServerError).statusCode === 401) {
    window.location.href = `/${LOGIN}`;
    localStorage.clear();
    operation.setContext({
      headers: {
        'x-api-key': reneApiKey,
        authorization: '',
      },
    });
  }
}) as ErrorLink.ErrorHandler);

// Initialize ApolloClient
const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      User: {
        keyFields: ['userId'],
      },
      Organization: {
        keyFields: ['orgId'],
        fields: {
          Balance: {
            keyArgs: ['balanceId'],
          },
        },
      },
    },
  }),
});

createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <NotificationProvider>
          <UpdatePopUpProvider>
            <ErrorProvider>
              <App />
            </ErrorProvider>
          </UpdatePopUpProvider>
        </NotificationProvider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
